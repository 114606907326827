.brands {
  padding: 8px 0 0;

  p,
  span {
    color: $text-color-secondary;
    font-size: 16px;
    line-height: 24px;
  }

  &__single {
    overflow: hidden;

    &-description {
      font-size: 16px;
      line-height: 24px;
    }

    h3 {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      @include min-respond(tablet) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-information {

      font-size: 18px;
      line-height: 24px;
      color: $lightblue;
      margin-bottom: 16px;

      h3 {

        position: relative;
        display: inline;
        user-select: none;

        &:after {
          position: absolute;
          content: '';
          width: 16px;
          height: 9px;
          right: -30px;
          top: 55%;
          transform: translateY(-50%);
          background-image: url('../../images/arrow-down.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right;
          transition: .3s all;
        }

      }

      &.active {
        h3 {
          &:after {
            top: 41%;
            transform: rotate(180deg);
          }
        }

      }

      &__content {
        margin-top: 15px;
        display: none;

        ul {
          li {
            color: $text-color-secondary;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      transition: .3s all;

      &:hover {
        cursor: pointer;
        opacity: .9;
      }

      .brands__single {
        &:after {
          display: none;
        }

        background: unset;
        padding: 15px 0;

        @include min-respond(tablet) {
          padding: 20px 0;
        }
      }
    }

    &-stats {
      font-size: 18px;
      line-height: 24px;
      color: $lightblue;
      margin-bottom: 18px;

      h3 {
        margin-bottom: 10px;

        @include min-respond(tablet) {
          margin-bottom: 20px;
        }
      }

      &__inner {
        @include min-respond(tablet) {
          display: flex;
        }

      }

      &__single {
        display: flex;
        margin-bottom: 5px;
        align-items: center;

        .icon {
          margin-right: 6.5px;
          width: 23px;
          display: flex;
          justify-content: center;

          img {
            object-fit: contain;
          }
        }

        span {
          @include min-respond(tablet) {
            display: flex;
            flex-direction: column;
            line-height: 15px;
          }
        }

        &:not(:last-child) {
          margin-right: 26px;
        }

      }

    }

    z-index: 10;
    position: relative;
    padding: 40px 0;
    background: rgba(1, 87, 155, .06);

    @include min-respond(tablet) {
      padding: 66px 0 66px;
    }

    &:before {
      z-index: -2;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: $brand-gradient-bg;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    &:after {
      z-index: -1;
      position: absolute;
      content: '';
      width: 100%;

      left: 0;
      top: 0;
      height: 650px;
      background-image: url('../../images/background.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;

      @include min-respond(tablet) {
        background-size: 1440px 1000px;
        height: 1000px;
      }
    }


    &-buttons {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      a {
        text-decoration: none;
      }

      @include min-respond(tablet) {
        flex-direction: row;
        justify-content: flex-start;

      }

      button {
        margin-bottom: 8px;
        margin-left: auto;
        margin-right: auto;

        @include min-respond(tablet) {
          max-width: 177px;
          margin-right: 10.5px;
        }
      }
    }

    &-logo {
      margin-bottom: 28px;
      display: flex;

      img {
        width: auto;
      }

    }

    &-mockup {
      display: none;

      @include min-respond(tablet) {
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100%;

      }

      &.tablet {
        img {
          width: auto;
          height: 380px;

          @include min-respond(tablet) {}
        }
      }

      &.desktop {
        img {
          width: auto;
          height: 240px;

        }

        @include min-respond(tablet) {
          margin-top: 25px;
        }
      }

      img {
        @include min-respond(tablet) {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }

  }

  .brands__single {

    &-mockup {
      max-height: 380px;

      img {
        max-width: 100%;
        object-fit: contain;
      }
    }

    &#sbc-podcasts {
      &:after {
        @include min-respond(tablet) {
          background-size: 1440px 1000px;
        }

      }

      .brands__single-mockup {

        max-height: 330px;

        img {
          max-width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  #slotbeats,
  #sbc-podcasts,
  #insider-sport {
    .brands__single-logo {
      img {
        height: 23px;

        @include min-respond(tablet) {
          height: 34px;
        }
      }
    }
  }

  #cgs,
  #cgsmagazine {
    .brands__single-logo {
      img {
        height: 23px;

        @include min-respond(tablet) {
          height: 42px;
        }
      }
    }
  }

  #sbc-noticias,
  #sbc-noticias-brasil {
    .brands__single-logo {
      img {
        height: 30px;

        @include min-respond(tablet) {
          height: 50px;
        }
      }
    }
  }

  #sbc-news,
  #sbc-americas,
  #sbc-digest,
  #casinobeats,
  #sbc-directory,
  #sbc-webinars {
    .brands__single-logo {
      img {
        height: 18px;

        @include min-respond(tablet) {
          height: 31px;
        }
      }
    }


  }


  #podcasts-sbcleaders,
  #esportsinsider,
  #sbc-leaders,
  #payment-expert {
    .brands__single-logo {
      img {
        height: 30px;

        @include min-respond(tablet) {
          height: 32px;
        }
      }
    }
  }

  #sbc-eurasia {
    .brands__single-logo{
      img {
        height: 28px;
        @include min-respond(tablet) {
          height: 46px;
        }
      }
    }
  }

  #casinobeats-weekly {
    .brands__single-logo {
      img {
        height: 38px;

        @include min-respond(tablet) {
          height: 52px;
        }
      }
    }
  }

  #gambling-tv {
    .brands__single-logo {
      img {
        height: 42px;

        @include min-respond(tablet) {
          height: 51px;
        }
      }
    }
  }

  #igaming-daily {
    .brands__single-logo {
      img {
        height: 42px;

        @include min-respond(tablet) {
          height: 49px;
        }
      }
    }

  }

  #podcasts-safebet,
  #podcasts-double {
    .brands__single-logo {
      img {
        height: 42px;

        @include min-respond(tablet) {
          height: 51px;
        }
      }
    }
  }

  #lottery-daily,
  #podcasts-slotbeats {
    .brands__single-logo {
      img {
        height: 34px;

        @include min-respond(tablet) {
          height: 51px;
        }
      }
    }
  }
}