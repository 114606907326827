@import "variables";

@import './variables.scss';

@mixin respond($breakpoint) {
  @if $breakpoint==mobile {
    @media only screen and (max-width: $md) {
      @content
    };
  }
  @if $breakpoint==tablet {
    @media only screen and (min-width: $md + 1px) and (max-width: $lg) {
      @content
    };
  }
  @if $breakpoint==desktop {
    @media only screen and (min-width: $xl + 1px) {
      @content
    };
  }
}

@mixin min-respond($breakpoint) {
  @if $breakpoint==tablet {
    @media only screen and (min-width: $lg + 1px) {
      @content
    };
  }
  @if $breakpoint==desktop {
    @media only screen and (min-width: $xxl + 1px) {
      @content
    };
  }
}

@mixin max-respond($breakpoint) {
  @if $breakpoint==mobile {
    @media only screen and (max-width: $md) {
      @content
    };
  }
  @if $breakpoint==tablet {
    @media only screen and (max-width: $xl) {
      @content
    };
  }
}