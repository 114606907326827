@import "../config/variables";

.main-header {
  &__wrapper {
    font-family: Roboto, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__navbar {

    z-index: 10;
    position: relative;
    height: 48px;
    background-color: white;;
    padding: 16px 19px;
    width: $main-container-mw-tablet;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: .3s all;

    &.scrolled {
      position: fixed;
      top: 0;
      z-index: 1000;
      @include min-respond(tablet) {
        height: 57px;
        padding-top: 0;
        padding-bottom: 0;
        justify-content: space-between;
        &:before {
          z-index: -1;
          content: '';
          position: absolute;
          left: -52%;
          top: 0;
          width: 150vw;
          height: 100%;
          background: white;

        }
        .main-header__nav--item-our-brands.active .sub-menu {
          top: 57px;
        }
        & + .main-header__banner {
          margin-top: 96px;
        }

        .main-header__nav--item-our-brands {
          .sub-menu {
            top: 57px;
          }
        }
      }
    }

    @include min-respond(tablet) {
      transition: unset;
      height: 96px;
      padding: 0;
      justify-content: space-between;
      width: $main-container-mw-tablet;
    }
    @include min-respond(desktop) {
      width: $main-container-mw-desktop;
    }
  }

  &__logo {

    display: flex;

    a {
      display: flex;
      align-items: center;
    }

    img {
      max-height: 15px;
      align-self: center;
      object-fit: contain;
      @include min-respond(tablet) {
        max-height: 21px;
      }
    }
  }

  &__more {
    img {
      max-height: 16px;
    }

    display: flex;

    &:hover {
      cursor: pointer;
      opacity: .9;
    }

    @include min-respond(tablet) {
      display: none;
    }
  }

  &__nav {
    display: none;
    color: $text-color-primary;
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    min-height: 200px;
    background-color: $lightgray;
    @include min-respond(tablet) {
      background-color: white;
      display: flex;
      min-height: unset;
      position: initial;
      width: auto;
    }

    &.opened {
      overflow: auto;
      max-height: 100vh;
    }

    ul {

      padding: 0 15px;
      list-style-type: none;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      @include min-respond(tablet) {
        flex-direction: row;
        margin-bottom: 0;
      }

      .main-header__nav--item {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 15px 0;
        position: relative;
        transition: .3s all;


        @include min-respond(tablet) {
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
        }

        &:not(:last-child) {
          @include min-respond(tablet) {
            padding-right: 40px;
          }

          &:after {
            position: absolute;
            content: '';
            width: 100%;
            top: 100%;
            left: 0;
            height: 1px;
            background-color: black;
            opacity: .1;
            @include min-respond(tablet) {
              display: none;
            }
          }


        }

        &:not(.active) {
          a {


            &:after {


              transform: translateY(-50%);

            }
          }
        }

        > a {
          font-weight: 400;
          transition: .3s all;
          text-decoration: none;
          white-space: nowrap;
          user-select: none;
          display: flex;
          height: 100%;
          align-items: center;
          color: $text-color-tertiary;
        }

        &.has-children {
          > a {
            &:after {
              content: '';
              position: absolute;
              background-image: url('../../images/arrow-down.svg');
              background-repeat: no-repeat;
              background-size: contain;
              top: 50%;
              right: 0;
              width: 16px;
              height: 9px;
              @include min-respond(tablet) {
                display: none;
              }
            }
          }
        }

        @include max-respond(tablet) {
          &.active {
            > a {
              position: relative;
              color: $lightblue;
              text-shadow: 0 0 .5px $lightblue, 0 0 .5px $lightblue;

              &:after {
                transform: rotate(180deg);
              }
            }
          }
        }
        @include min-respond(tablet) {
          &:hover {
            cursor: pointer;

            > a {
              color: $lightblue;
              text-shadow: 0 0 .5px $lightblue, 0 0 .5px $lightblue;
            }

          }
        }


      }
    }

    &--item {
      position: relative;
      text-transform: uppercase;

      &-more {
        position: relative;


        @include min-respond(tablet) {
          &:hover {
            > .sub-menu {
              opacity: 1;
              display: flex;
              flex-direction: column;
            }

          }
        }

        @include max-respond(tablet) {
          &.active {
            > .sub-menu {
              margin-top: 10px;
              margin-bottom: 10px;
              opacity: 1;
              display: flex;
              flex-direction: column;
              width: 100%;
            }

          }
        }

        > .sub-menu {
          @include min-respond(tablet) {
            position: absolute;
          }
          text-transform: initial;
          width: 188px;
          padding: 0 15px;

          font-size: 14px;
          //  display: none;
          transition: .3s all;
          top: 100%;
          left: 0;
          opacity: 0;
          display: none;
          background: $lightgray;

          > .more__single {
            a {
              color: inherit;
              position: relative;
              display: flex;
              padding-top: 13px;
              padding-bottom: 13px;

              &:hover {
                color: $lightblue;

                &:before {
                  position: absolute;
                  width: 4px;
                  height: 100%;

                  content: '';
                  top: 0;
                  left: -15px;
                  background: $lightblue;
                }
              }
            }


            &:not(:last-child) {
              > a {
                position: relative;


                &:after {
                  position: absolute;
                  width: 100%;
                  height: 1px;
                  opacity: .1;
                  content: '';
                  top: 100%;
                  left: 0;
                  background: black;
                }
              }


            }

            .more__single-event {

              a {
                text-decoration: none;
                padding-left: 15px;

                &:after {
                  position: absolute;
                  width: 100%;
                  height: 1px;
                  opacity: .1;
                  content: '';
                  top: 100%;
                  left: 0;
                  background: black;
                }
              }
            }

            > .sub-menu {
              padding: 0;
              display: flex;
              flex-direction: column;
            }
          }
        }


      }


      &-our-brands {
        transition: .3s all;

        .sub-menu {
          opacity: 0;
          display: none;
          transition: .3s all;
          padding: 15px 10px;

          gap: 4.16px;
          grid-template-columns: 1fr 1fr;
          justify-items: center;
          border-radius: 0px 0px 6px 6px;
          @include min-respond(tablet) {
            margin-top: 0;
            box-shadow: 1px 8px 15px rgba(0, 0, 0, .3);
            background-color: $lightgray;
            gap: 3.11px;
            padding: 20px;
            position: absolute;
            top: 96px;
            left: -300px;
            width: 854px;
            min-height: 309px;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          }

          li {
            width: 134px;
            height: 57px;
            background: white;
            border: 1px solid #F2F2F2;
            border-radius: 6px;
            @include min-respond(tablet) {
              width: 160px;
              height: 65px;
            }
          }
        }

        @include min-respond(tablet) {
          &:hover {
            .sub-menu {
              opacity: 1;
              display: grid;
            }
          }
        }

        @include max-respond(tablet) {
          &.active {
            .sub-menu {
              opacity: 1;
              display: grid;
            }
          }
        }
      }
    }
  }

  &__banner {
    z-index: 9;
    position: relative;
    background: url('../../images/hero_banner_mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 335px;
    width: 100%;
    display: flex;
    justify-content: center;

    @include min-respond(tablet) {
      z-index: 1;
      min-height: 524px;
      background-image: url('../../images/hero_banner.png');
    }

    .btn-lightblue {
      align-self: flex-end;
      margin-left: auto;
      margin-right: auto;
      @include min-respond(tablet) {
        margin-left: unset;
        margin-right: unset;
      }
    }

    &-content {
      padding: 26.5px;
      @include min-respond(tablet) {
        padding: 26.5px 0;
      }

      h1 {
        display: none;
        @include min-respond(tablet) {
          display: block;
          font-weight: 300;
          font-size: 53px;
          line-height: 48px;
          color: $lightblue;
          margin-bottom: 38px;
        }
      }

      h3 {

        display: none;
        @include min-respond(tablet) {
          display: block;
          font-weight: 400;
          font-size: 23px;
          line-height: 38px;
          color: $text-color-secondary;
          strong {
            font-weight: 900;
          }
          margin-bottom: 38px;
        }
      }
    }

    .container {
      z-index: 9;
      justify-content: flex-end;
      @include min-respond(tablet) {
        justify-content: center;
      }
    }
  }
}