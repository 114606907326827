.about {
  padding: 38px 0 32px;
  @include min-respond(tablet) {
    padding: 60px 0;
  }

  &__heading {
    color: $lightblue;
    margin-bottom: 16px;
    @include min-respond(tablet) {
      text-align: left;
    }
  }

  strong {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: $text-color-secondary;
  }

  ul {
    padding-left: 20px;
    @include min-respond(tablet) {
      > li {
        font-size: 18px;
        line-height: 32px;
      }

    }
  }

  p, li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $text-color-secondary;

    @include min-respond(tablet) {
      font-size: 18px;
      line-height: 32px;
    }
  }


  p {
    margin-bottom: 32px;
  }

  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }

  h3 {
    color: $lightblue;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  button {
    margin: 10px auto 0;
    font-weight: 500;
    @include min-respond(tablet) {
      margin-right: auto;
      margin-left: 0;
    }

  }

  .container {
    @include respond(tablet) {
      .col-right {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

    }
    a{
      padding-left: 0;
    }
  }
}

.statistics {
  padding-top: 24px;
  padding-bottom: 45px;
  min-height: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include min-respond(tablet) {
    padding-top: 54px;
  }

  &__inner {
    @include min-respond(tablet) {
      min-height: 170px;
    }

  }

  &__single {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-icon {
      z-index: 10;
      position: relative;
      height: 34px;
      @include min-respond(tablet) {
        height: 52px;

      }

      &:before {
        z-index: -1;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 69px;
        width: 69px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0px 0px 13px rgba(1, 87, 155, 0.15);
        @include min-respond(tablet) {
          height: 107px;
          width: 107px;
        }
      }


      img {
        z-index: 10;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    &-count {
      font-weight: 900;
      font-size: 20px;
      line-height: 16px;
      text-align: center;
      margin: 28px 0 6px;
      @include min-respond(tablet) {
        font-size: 36px;
        line-height: 32px;
        margin: 42px 0 6px;
      }
    }

    &-type {
      font-weight: 900;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      white-space: nowrap;
      @include min-respond(tablet) {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }


  &__heading {
    margin-bottom: 36px;
    @include min-respond(tablet) {
      margin-bottom: 56px;
    }

  }
}

.our-brands, .statistics {
  background: $lightgray-secondary;
  color: $lightblue;

}

.our-brands {
  .container {
    padding-top: 30px;
    padding-bottom: 33px;
    @include min-respond(tablet) {
      padding-top: 36px;
      padding-bottom: 91px;
    }
  }

  margin-top: 2px;
  @include min-respond(tablet) {
    margin-top: 5px;
  }

  &__heading {
    margin-bottom: 20px;
    @include min-respond(tablet) {
      margin-bottom: 51px;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    gap: 2px;
    @include min-respond(tablet) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 3.85px;
      max-width: $main-container-mw-tablet;
      margin-left: auto;
      margin-right: auto;
    }

  }

  &__single {
    background: white;
    border-radius: 6px;
    padding: 0 12px;
    width: 95px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all;
    @include min-respond(tablet) {
      height: 48.5px;
      width: 114px;
      &.filler {
        display: none;
      }
    }

    &:hover {
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
      cursor: pointer;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}

.testimonials {
  @include min-respond(tablet) {
    .container {
      max-width: 1008px;
      overflow: hidden;
    }
  }
  @include min-respond(desktop) {
    .container {
      max-width: 1260px;
      overflow: hidden;
    }
  }

  &__single {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 242px;
    padding: 0;
    background: white;
    border-radius: 5.83835px;
    height: 290px;
    @include min-respond(tablet) {
      height: 325px;

    }

    &-content {
      position: relative;
      padding: 20px 18px 16px;
      text-align: center;
      color: $text-color-secondary;
      font-weight: 400;
      font-size: 13.3448px;
      line-height: 20px;
      @include min-respond(tablet) {
        font-size: 16px;
        line-height: 24px;
      }

      p {
        margin-bottom: 0;
      }

      &:before {
        z-index: 0;
        position: absolute;
        content: '';
        background-image: url('../../images/quote.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        width: 48px;
        height: 48px;
        left: 21px;
        top: 15px;
      }
    }

    &-author {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      background: #F0F4F7;
      border-radius: 0 0 5.83835px 5.83835px;
      padding: 17px 30px;
      @include min-respond(tablet) {
        justify-content: flex-start;
      }
      .avatar {
        margin-right: 10px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
        width: 74px;
        height: 74px;


        &-image {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          width: 100%;
          height: 100%;
          max-width: 86%;
          max-height: 100%;


        }
      }

      .data {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding-left: 0;
        padding-right: 0;
        color: $text-color-secondary;

        h4 {
          font-weight: 700;
          font-size: 13.4px;
          line-height: 18px;
          margin-bottom: 0;
          @include min-respond(tablet) {
            font-size: 16px;
            line-height: 22px;
          }
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          font-style: italic;
          margin-bottom: 0;
        }
      }
    }
  }


  padding: 40px 0 40px;
  background: $lightblue;
  @include min-respond(tablet) {
    padding: 51px 0 70px;
  }

  &__heading {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 48px;
    color: white;
    @include min-respond(tablet) {
      font-weight: 300;
      font-size: 38px;
      line-height: 48px;
      margin-bottom: 25px;
    }
  }

  .swiper {

    margin-left: unset;
    margin-right: unset;
    height: 325px;

    @include min-respond(tablet) {
      max-width: $main-container-mw-tablet;
      overflow: visible;
      margin-left: auto;
      margin-right: auto;
      height: 365px;
    }
    @include min-respond(desktop) {
      max-width: $main-container-mw-desktop;
    }

    &-button {
      &-next, &-prev {
        display: none;
        @include min-respond(tablet) {

          color: white;
          display: flex;
          top: 43%;
          &:after {
            font-size: 25px;
          }
        }

      }

      &-prev {
        left: -37px;
      }

      &-next {
        right: -37px;
      }

    }

    &-pagination {
      bottom: -5px;

      &-bullet {
        background: white;
        width: 12px;
        height: 12px;
      }
    }

    &-slide-visible {
      box-shadow: 0px 8.34051px 13.3448px rgba(0, 0, 0, 0.13);
    }
  }

}

.with-us {
  position: relative;
  padding: 53px 0 13px;

  @include min-respond(tablet) {
    &:before {
      z-index: -1;
      position: absolute;
      content: '';
      background-image: url('../../images/brochure-desktop.png');
      background-repeat: no-repeat;
      background-position: right;
      width: 100%;
      height: 1010px;
      right: 0;
      top: 0;
    }
    padding: 67px 0 13px;

  }

  button {
    margin: -40px auto 0;
    @include min-respond(tablet) {
      margin-top: 31px;
    }
  }

  ul {
    padding-left: 0;
    padding-right: 0;
    list-style-position: inside;
    color: $text-color-secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;

  }

  &__heading {
    color: $lightblue;
    margin-bottom: 16px;
    @include min-respond(tablet) {
      margin-bottom: 26px;
      text-align: left;
    }

  }

  &__brochure {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      @include min-respond(tablet) {
        display: none;
      }
    }

    @include min-respond(tablet) {
      display: initial;
      justify-content: initial;
      margin-right: auto;
      margin-left: 0;
    }
  }

  button {
    @include min-respond(tablet) {
      margin-right: auto;
      margin-left: 0;
    }
  }

  .container {
    @include respond(tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.contact {
  @include min-respond(tablet) {
    min-height: 600px;
  }

  fieldset {
    @include respond(tablet) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  textarea:focus, input:focus {
    outline: none;
  }

  padding: 20px 0 53px;
  color: $lightblue;
  @include min-respond(tablet) {
    padding: 57px 0 80px;
  }

  &__heading {
    margin-bottom: 16px;
    @include min-respond(tablet) {
      margin-bottom: 20px;
      text-align: left;
    }
  }

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: $text-color-tertiary;
    @include min-respond(tablet) {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      margin-bottom: 0;
    }
  }

  .hbspt-form {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include min-respond(tablet) {
        display: initial;
      }
    }

    fieldset {
      width: 100%;
      @include min-respond(tablet) {
        width: auto;
      }

      input, textarea {
        background-color: transparent;
      }
    }
  }
  .hs-form-field {
    border: none;
    position: relative;


  }

  .submitted-message {
    @include respond(mobile) {
      margin: 0 auto;
    }
    padding-top: 20px;
    max-width: 325px;

    p {
      color: red;
    }

  }

  .hs-input {
    border: none;
    margin-right: 15px;
    padding: 10px 0;

    &::placeholder {
      color: #ACACAC;
      @include min-respond(tablet) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

    }
  }

  .hs-fieldtype-booleancheckbox {
    font-size: 11px;
    color: $text-color-secondary;

    .input {
      margin-top: 20px;

      &:after {
        display: none;
      }
    }
  }

  .input {
    @include min-respond(tablet) {
      margin-right: 15px;
    }

    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background: $text-color-tertiary;
    }
  }

  ul {
    padding-left: 0;

    list-style-type: none;

    .hs-error-msg {
      font-size: 12px;
      margin-top: 8px;
      color: red;
    }
  }

  .hs-button {
    background: $lightblue;
    color: white;
    border-width: 0;
    width: 223px;
    height: 47px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    @include min-respond(tablet) {
      margin-right: auto;
      margin-left: 0;
      margin-top: 15px;
    }

    &:hover {
      opacity: .8;
      transition: .3s all;
    }
  }
}

.working {
  padding: 45px 0 66px;
  background: $lightgray-secondary;
  @include min-respond(tablet) {
    padding: 50px 0 111px;
  }

  &__jobs {
    @include min-respond(tablet) {
      width: 900px;
      margin: 0 auto;
    }
  }

  &__heading {
    color: $lightblue;
    @include min-respond(tablet) {
      margin-bottom: 32px;
    }
  }

  .BambooHR-ATS-board {
    .BambooHR-ATS-Department-Header {
    }

    h2 {
      font-family: 'Lato', sans-serif !important;
      text-transform: uppercase;
      border-bottom: unset !important;
      font-weight: 400 !important;;
      font-size: 20px !important;;
      line-height: 32px !important;;
      text-align: center !important;;
      color: $lightblue !important;
      @include min-respond(tablet) {
        margin-top: 32px;
        margin-bottom: 32px !important;
        font-weight: 300 !important;;
        font-size: 38px !important;;
        line-height: 48px !important;;
        border-bottom: unset !important;
      }
    }

    p, li {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;


      @include min-respond(tablet) {
        font-size: 18px;
        line-height: 32px;
      }
    }

    li a {
      transition: .3s all;
      line-height: 24px;
      color: $text-color-secondary;
      font-weight: 600;

      @include min-respond(tablet) {
        line-height: 32px;
      }

      &:hover {
        opacity: .9;
      }
    }

    h2 {
      margin-top: 20px;
    }

    @include max-respond(tablet) {

      .BambooHR-ATS-Jobs-Item {
        display: flex;
        flex-direction: column;

        span {
          float: unset;
        }
      }
      .BambooHR-ATS-Department-Header {
        font-size: 19px;
        padding-bottom: 18px;
      }
      .BambooHR-ATS-Location {
        padding-top: 0 !important;
        padding-bottom: 20px !important;
      }
    }

    .BambooHR-ATS-Jobs-Item a, .BambooHR-ATS-Location {
      font-size: 14px;


      @include min-respond(tablet) {
        font-size: 16px;
      }
    }

    ul ul {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 17px;
    }
  }

  .BambooHR-ATS-board .BambooHR-ATS-Location {
    padding-top: 19px;
    @include min-respond(tablet) {
      padding-top: 0;
    }
  }

  .BambooHR-Footer {
    margin-top: 30px;
  }

  &__content {
    p {
      color: $text-color-secondary;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      @include min-respond(tablet) {
        text-align: center;
        margin-bottom: 25px;
      }
    }

  }
}
