.footer {
  a{
    color: white;
    transition: .3s all;
    &:hover{
      opacity: .9;
    }
  }
  background: $darknavy;
  min-height: 336px;
  color: white;
  padding: 25px 21px;
  @include min-respond(tablet) {
    padding: 57px 145px;
    min-height: 224px;
  }

  &-logo {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    img {
      height: 32px;
      width: auto;
      @include min-respond(tablet) {
        height: 50px;
      }
    }

    @include min-respond(tablet) {
      margin-bottom: 0;
    }

  }

  &-info {
    color: white;

    h4 {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      @include min-respond(tablet) {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
      }
    }

    p {
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      margin-bottom: 0;
      @include min-respond(tablet) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    @include min-respond(tablet) {
      > .col:last-child {
        padding-left: 60px;
        padding-right: 40px;
      }
    }


  }

  @include min-respond(tablet) {
    &-logo, &-address, &-contacts, &-terms {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &-address, &-contacts {
    margin-bottom: 15px;
    @include min-respond(tablet) {
      margin-bottom: 0;
    }
  }

  @include min-respond(tablet) {
    margin-bottom: 0;
  }

  &-terms {
    a{
      text-decoration: underline;
      color: white;
    }
    p:last-child {
      margin-top: 20px;
      @include min-respond(tablet) {
        margin-top: 0;
      }
    }
  }

  &-socials {
    margin-top: 20px;
    @include min-respond(tablet) {
      margin-top: 0;
    }
    a {
      &:not(:last-child) {
        padding-right: 25px;
        @include min-respond(tablet) {
          padding-right: 15px;
        }
      }

      transition: .3s all;

      &:hover {
        opacity: .9;
      }
    }
  }
}