//media

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;


//colors
$lightblue: #01579B;
$darkblue: #084271;
$header-gradient-bg: linear-gradient(360deg, rgba(236, 236, 236, 0.28) 0%, rgba(255, 255, 255, 0) 101.76%);
$brand-gradient-bg: linear-gradient(180deg, #FCFCFC 50%, rgba(248, 249, 249, 0.0001) 100%);
$lightgray: #FDFDFD;
$text-color-primary: #4D4D4D;
$text-color-secondary: #5F5F5F;
$text-color-tertiary: #404042;
$lightgray-secondary: rgba(1, 87, 155, 0.05);
$darknavy: #000019;
//content
$main-container-mw-tablet: 942px;
$main-container-mw-desktop: 1200px;