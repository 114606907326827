@import "config/variables";
@import "config/media";
@import "~bootstrap/scss/bootstrap";
@import "partials/header";
@import "partials/container";
@import "partials/brands";
@import "partials/footer";
@import "~vanilla-cookieconsent/dist/cookieconsent.css";

body {

  background: white;
  min-height: 100vh;
  font-family: 'Lato', sans-serif;
  max-width: 100vw;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

main {
  min-height: 100%;
}

h1, h2, h3, h4, h5, h6, p, strong, pre, span, a, li {
  font-family: 'Lato', sans-serif;
}

h2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  @include min-respond(tablet) {
    font-weight: 300;
    font-size: 38px;
    line-height: 48px;
  }
}

.main-wrapper {
  max-width: 100%;
}

.container {
  display: flex;
  max-width: $main-container-mw-tablet;
  margin: 0 auto;
  padding: 0 12.5px;
  @include respond(tablet) {
    padding: 20px 50px;
  }
  @include min-respond(tablet) {
    padding: 0;
  }
  @include min-respond(desktop) {
    max-width: $main-container-mw-desktop;
  }

  &:not(.row) {
    flex-direction: column;
  }
}

a {
  text-decoration: none;
}

button {
  &.btn-lightblue, &.btn-darkblue, &.btn-white {
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  &:hover {
    cursor: pointer;
  }
}

.btn-lightblue {
  background: $lightblue;
  color: white;
  border-width: 0;

  &:hover {
    opacity: .8;
    transition: .3s all;
  }
}

.btn-darkblue {
  border-width: 0;
  background: $darkblue;
  color: white;

  &:hover {
    opacity: .8;
    transition: .3s all;
  }
}

.btn-white {
  border: 1px solid $lightblue;
  background: transparent;
  color: $lightblue;

  &:hover {
    opacity: .8;
    transition: .3s all;
  }
}

.scrollTop {
  display: none;
  opacity: 0;
  z-index: -10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 70px;
  bottom: 30px;
  text-align: center;

  p {
    margin-top: 10px;
    color: $lightblue;
    font-weight: 700;
    font-size: 11px;
    line-height: 8px;
  }

  transition: .3s all;

  button {
    margin: 0 auto;
    border-width: 0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: $lightblue;
  }

  @include min-respond(tablet) {
    opacity: 1;
    z-index: 10;
    &:hover {
      opacity: .8;
    }
  }

}